/* You can add global styles to this file, and also import other style files */
/* * {
  box-sizing: border-box;
  outline: 0 !important;
  font-family:'Arial', sans-serif !important;
} */

/* body {
  font-family: 'Roboto', roboto, sans-serif;
  padding: 0;
  margin: 0;
} */

/* .mat-menu-item,
.mat-tooltip {
  font-family:  'Arial', sans-serif !important;
} */
/* styles for headings */
/* h1{
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 1.2 !important;
  font-family: 'Montserrat' !important;
} */
/* h2{
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 1.2 !important;
  font-family: 'Montserrat' !important;
} */
/* width */
/* ::-webkit-scrollbar {
  width: 6px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

/* .material-icon-cust {
  cursor: pointer;
  color: #0075A9;
} */


body { margin: 0; font-family:'Poppins' !important;
  width: 100vw;
  overflow-x: hidden;
  left: 0}

  @font-face {
    font-family: "Poppins";
    src: local("Poppins"), url(./assets/fonts/poppins/Poppins-Regular.ttf) format("truetype");
  }
  @import "~@angular/material/prebuilt-themes/indigo-pink.css";
  
  
  
  @media (max-width: 768px) {
      /* Font sizes for tablets and larger screens */
      html {
        font-size: 12px;
      }
    }
    
    @media (min-width: 768px) {
      /* Font sizes for tablets and larger screens */
      html {
        font-size: 15px;
      }
    }
    
    @media (min-width: 1200px) and (min-height: 700px) {
      /* Font sizes for tablets and larger screens */
      html {
        font-size: 20px;
      }
    }
  
    @media (min-width: 1200px) and (min-height: 700px) {
      /* Font sizes for tablets and larger screens */
      html {
        font-size: 20px;
      }
    }
    